import { useState } from 'react';

import Link from 'next/link';

import useScrollDirection from '@/hooks/useScrollDirection';
import useWindowSize, { Size } from '@/hooks/useWindowSize';
import { INavigation } from '@/interfaces/navigation';

import Logo from './Logo.svg';
import Navigation from './Navigation';
import MenuButton from './Navigation/MenuButton';
import Search from './Search';
import { HeaderRow, LogoWrap, StyledHeader } from './styles';

interface IHeader {
    items: Array<INavigation>;
}

const Header = ({ items }: IHeader) => {
    const [navActive, setNavActive] = useState(false);
    const scrollDirection = useScrollDirection();
    const size: Size = useWindowSize();

    return (
        <StyledHeader className={`scroll-${scrollDirection} ${navActive ? 'active' : ''}`}>
            <HeaderRow size="wide">
                <LogoWrap>
                    <Link href="/" title="Go City">
                        <Logo width={120} height={36} />
                    </Link>
                </LogoWrap>
                <Navigation items={items} navActive={navActive} setNavActive={setNavActive} />
                <Search />
                {size.width <= 970 && <MenuButton navActive={navActive} setNavActive={setNavActive} />}
            </HeaderRow>
        </StyledHeader>
    );
};

export default Header;
