import { Hamburger, MobileButton } from './styles';

interface IMenuButton {
    setNavActive: React.Dispatch<React.SetStateAction<boolean>>;
    navActive: boolean;
}

const index = ({ setNavActive, navActive }: IMenuButton) => {
    const clickHandler = () => {
        setNavActive((prev) => !prev);
        if (!navActive) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    };

    return (
        <MobileButton onClick={clickHandler}>
            {!navActive ? 'Menu' : 'Close'}
            <Hamburger className={navActive ? 'active' : ''}>
                <div></div>
            </Hamburger>
        </MobileButton>
    );
};

export default index;
