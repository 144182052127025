import useScrollDirection from '@/hooks/useScrollDirection';

import { StyledBackToTop } from './styles';

const BackToTop = () => {
    const scrollDirection = useScrollDirection();
    return <StyledBackToTop href="#top" className={`scroll-${scrollDirection}`}></StyledBackToTop>;
};

export default BackToTop;
