import styled from 'styled-components';

export const Item = styled.li`
    position: relative;
    margin: 0;

    a {
        display: block;
        text-decoration: none;
    }

    button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
        position: relative;

        span.arrow {
            content: '';
            width: 2rem;
            height: 2rem;
            position: absolute;
            right: 1rem;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colours.secondary.chartreuse};
            top: 50%;
            transform: translateY(-50%);
            padding: 10px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: ${({ theme }) => theme.colours.surface.deepBlue};
            }

            &:before {
                top: 25%;
                left: 50%;
                width: 2px;
                height: 50%;
                margin-left: -1px;
                transition: transform 0.25s ease-out;
            }

            &:after {
                top: 50%;
                left: 25%;
                width: 50%;
                height: 2px;
                margin-top: -1px;
            }
        }

        &[aria-expanded='true'] {
            span.arrow:before {
                transform: rotate(90deg);
            }
        }
    }

    a,
    button {
        font-size: inherit;
        font-weight: inherit;
        text-align: left;
        color: inherit;
        padding: 0.7rem 1rem;

        @media (min-width: 900px) and (max-width: 1250px) {
            padding: 0.7rem 0.4rem;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        a,
        button {
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            height: 100%;
            &:hover {
                color: ${({ theme }) => theme.colours.primary.darkTeal};
            }
        }

        button {
            padding: 0;

            span.arrow {
                display: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0.25rem;
            width: 100%;
            background-color: transparent;
            transition: background-color 0.3s ease-in;
        }

        &.current:after {
            background-color: ${({ theme }) => theme.colours.primary.darkTeal};
        }

        .dropdown .current:after {
            display: none;
        }
    }
`;
