import styled from 'styled-components';

export const FooterWrap = styled.footer`
    color: ${({ theme }) => theme.colours.surface.default};
    background-color: ${({ theme }) => theme.colours.surface.deepBlue};
    padding: 2rem 0;
`;

export const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    a {
        color: ${({ theme }) => theme.colours.surface.default};
        text-decoration: none;
        font-size: 0.9rem;
        font-weight: 600;
        transition: color 0.3s ease-in;
        font-family: ${({ theme }) => theme.font.urbanist};

        &:hover {
            color: ${({ theme }) => theme.colours.primary.lightTeal};
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
        align-items: flex-start;
        gap: 2rem;
    }
`;
