import styled from 'styled-components';

import { Container } from '@/styles/global';

export const StyledHeader = styled.header`
    transition: transform 0.3s ease-in;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colours.surface.default};
    z-index: 900;

    &.scroll-down {
        transform: translateY(0);
    }

    &.scroll-up {
        transform: translateY(0);
    }

    &.active {
        transform: translateY(0);
    }
`;

export const HeaderRow = styled(Container)`
    display: flex;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding: 0.8rem 1rem;
        background-color: white;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        justify-content: center;
    }
`;

export const LogoWrap = styled.div`
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-right: auto;
        padding: 0.5rem 0 0.2rem;

        svg {
            max-width: 90px;
            height: auto;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        order: 1;
        padding: 1rem 0;
    }
`;
