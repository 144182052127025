import styled from 'styled-components';

export const StyledDropdown = styled.ul`
    margin: 0;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    list-style: none;
    display: none;
    font-size: 1rem;
    padding: 0;

    &.show {
        display: block;
    }

    .dropdown-submenu {
        position: absolute;
        left: 100%;
        top: -7px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        position: absolute;
        left: 0;
        border-radius: 0 0 0.75rem 0.75rem;
        background-color: white;
        padding: 0;
        min-width: 17.2rem;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colours.surface.secondary};

        li a,
        li button {
            &:hover {
                color: ${({ theme }) => theme.colours.text.default};
                background-color: ${({ theme }) => theme.colours.primary.lightTeal};
            }
        }
    }
`;
