import styled from 'styled-components';

export const MobileButton = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colours.surface.deepBlue};
    font-weight: 600;
    font-size: 0.875rem;
    border-left: 2px solid #d9dbe9;
    padding-left: 0.78rem;
    margin-left: 0.78rem;
    width: 5.93rem;
`;

export const Hamburger = styled.div`
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    z-index: 999;

    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 2px;
        background-color: ${({ theme }) => theme.colours.surface.deepBlue};
        border-radius: 1rem;
        transform: translate(-50%, -50%);

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: 20px;
            height: 2px;
            border-radius: 1rem;
            background-color: ${({ theme }) => theme.colours.surface.deepBlue};
        }

        &:before {
            transform: translateY(-5px);
            animation: burger3 0.3s linear;
        }

        @keyframes burger3 {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(-5px) rotate(0deg);
            }
        }

        &:after {
            transform: translateY(5px);
            animation: burger4 0.3s linear;
        }

        @keyframes burger4 {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(5px) rotate(0deg);
            }
        }
    }
    &.active {
        > div {
            visibility: hidden;

            &:before,
            &:after {
                visibility: visible;
                background-color: $white;
                transform-origin: center center;
                transform: translateY(0);
                transition: all 0.3s linear;
            }

            &:before {
                transform: rotate(-45deg);
                animation: burger1 0.3s linear;
            }

            @keyframes burger1 {
                0% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(0) rotate(-45deg);
                }
            }

            &:after {
                transform: rotate(45deg);
                animation: burger2 0.3s linear;
            }

            @keyframes burger2 {
                0% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(0) rotate(45deg);
                }
            }
        }
    }
`;
