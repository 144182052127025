import Link from 'next/link';
import styled from 'styled-components';

export const StyledBackToTop = styled(Link)`
    color: ${({ theme }) => theme.colours.surface.default};
    background-color: ${({ theme }) => theme.colours.secondary.chartreuse};
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    background-image: url('/assets/img/icons/arrow-dark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2rem auto;
    transform: rotate(-90deg);
    transition: visibility 0s 0.3s, opacity 0.3s linear;
    visibility: hidden;
    opacity: 0;
    z-index: 880;

    &.scroll-up,
    &.scroll-down {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2rem;
        bottom: 1.5rem;
        right: 1.5rem;
    }
`;
