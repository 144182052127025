import styled from 'styled-components';

export const Nav = styled.nav`
    position: relative;
    color: ${({ theme }) => theme.colours.surface.deepBlue};
    font-family: ${({ theme }) => theme.font.urbanist};
    flex: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        background-color: ${({ theme }) => theme.colours.surface.deepBlue};
        color: white;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(-100%);
        visibility: hidden;
        z-index: -1;
        height: 100vh;
        transition: transform 0.5s ease, visibility 0s ease 0.5s;
        padding-top: 5.75rem;

        &.active {
            visibility: visible;
            transition: transform 0.5s ease, visibility 0s ease 0s;
            transform: translateY(0);
        }
    }
`;

export const NavUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 600;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
        font-size: 0.875rem;
        height: 100%;
    }
`;
