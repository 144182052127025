import Link from 'next/link';
import styled from 'styled-components';

export const StyledSearch = styled.div`
    display: flex;
    align-items: center;

    a.search {
        display: block;
        text-decoration: none;
        color: ${({ theme }) => theme.colours.surface.deepBlue};
        font-weight: 600;
        font-size: 0.875rem;
        transition: all 0.3s ease-in;

        &:hover {
            color: ${({ theme }) => theme.colours.primary.darkTeal};
        }
        @media (min-width: 900px) and (max-width: 1250px) {
            display: none;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        order: 2;
        flex: 1;
        color: ${({ theme }) => theme.colours.surface.deepBlue};
        justify-content: flex-end;
        align-items: center;
        gap: 0.8rem;

        span {
            font-size: 0.875rem;
            font-weight: 600;
            font-family: ${({ theme }) => theme.font.urbanist};
        }
    }
`;

export const MobileCTA = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colours.surface.deepBlue};
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none;

    svg {
        margin-left: 0.5rem;
    }

    @media (max-width: 500px) {
        display: none;
    }
`;
