/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef, useCallback, SetStateAction } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { INavigation } from '@/interfaces/navigation';
import useWindowSize, { Size } from 'src/hooks/useWindowSize';

import Dropdown from '../Dropdown';
import { Item } from './styles';

interface INavbar {
    items: INavigation;
    depthLevel: number;
    setNavActive: React.Dispatch<SetStateAction<boolean>>;
}

const MenuItem = ({ items, depthLevel, setNavActive }: INavbar) => {
    const [dropdown, setDropdown] = useState(false);
    const size: Size = useWindowSize();
    const { asPath } = useRouter();

    const { text, url, submenu } = items;

    const ref = useRef();

    useEffect(() => {
        const handler = (e) => {
            // @ts-ignore
            if (dropdown && ref.current && !ref.current.contains(e.target)) {
                setDropdown(false);
            }
        };

        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = useCallback(() => {
        size.width > 960 && setDropdown(true);
    }, [size.width]);

    const onMouseLeave = useCallback(() => {
        size.width > 960 && setDropdown(false);
    }, [size.width]);

    const closeDropdown = useCallback(() => {
        dropdown && setDropdown(false);
    }, [dropdown]);

    const clickHandler = useCallback(() => {
        setNavActive(false);
        document.body.classList.remove('no-scroll');
    }, [setNavActive]);

    return (
        <Item
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeDropdown}
            className={asPath === url ? 'current' : ''}
        >
            {url && submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {size.width < 960 && depthLevel === 0 ? text : <Link href={url}>{text}</Link>}

                        {depthLevel > 0 && size.width < 960 ? null : depthLevel > 0 && size.width > 960 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={submenu}
                        dropdown={dropdown}
                        setNavActive={setNavActive}
                    />
                </>
            ) : !url && submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {text} {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={submenu}
                        dropdown={dropdown}
                        setNavActive={setNavActive}
                    />
                </>
            ) : (
                <Link href={url} onClick={clickHandler}>
                    {text}
                </Link>
            )}
        </Item>
    );
};

export default MenuItem;
