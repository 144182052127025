import { useCallback, useContext, useState } from 'react';

import Link from 'next/link';

import { Dropdown } from '@/components/Dropdown';
import useWindowSize, { Size } from '@/hooks/useWindowSize';
import { Button, TextInputSmall } from '@/styles/global';
import { JobsContext } from 'src/context/jobsContext';

import SearchIcon from './Search.svg';
import { MobileCTA, StyledSearch } from './styles';

const ALL_FILTER = 'all';

const Search = () => {
    const size: Size = useWindowSize();
    const { locations } = useContext(JobsContext);
    const allLocations = [ALL_FILTER, ...locations];
    const [keyword, setKeyword] = useState('');
    const [location, setLocation] = useState('');

    const keywordChangeHandler = useCallback((ev) => {
        setKeyword(ev.target.value);
    }, []);

    const locationChangeHandler = useCallback((ev) => {
        setLocation(ev.target.value);
    }, []);

    return (
        <StyledSearch role="search">
            {size.width <= 1095 ? (
                <MobileCTA href="/jobs">
                    Search jobs <SearchIcon width={24} height={24} />
                </MobileCTA>
            ) : (
                <>
                    <Link href="/jobs" className="search">
                        Search jobs
                    </Link>
                    <TextInputSmall
                        placeholder="Keyword"
                        value={keyword}
                        onChange={keywordChangeHandler}
                        aria-label="Keyword"
                    />
                    <Dropdown
                        placeholder="Location"
                        name="location"
                        value={location}
                        options={allLocations}
                        onChange={locationChangeHandler}
                        aria-label="Location"
                    />
                    <Button
                        size="small"
                        href={{
                            pathname: '/jobs',
                            query: {
                                keyword: keyword,
                                location: location,
                            },
                        }}
                        scroll={false}
                        aria-label="Search"
                    >
                        Search
                    </Button>
                </>
            )}
        </StyledSearch>
    );
};

export default Search;
