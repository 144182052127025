import styled from 'styled-components';

export const Select = styled.select`
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    color: ${({ theme }) => theme.colours.text.default};
    border: 1px solid ${({ theme }) => theme.colours.surface.brandHighlight};
    background-color: ${({ theme }) => theme.colours.surface.secondary};
    font-size: 0.875rem;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_13_37273' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='15' height='9'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0664062 0.622559H14.0664V8.62256H0.0664062V0.622559Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_13_37273)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.91 0.82931C13.1745 0.553641 13.6035 0.553641 13.868 0.82931C14.1325 1.10498 14.1325 1.55191 13.868 1.82758L7.5454 8.4158C7.41313 8.55363 7.23977 8.62256 7.06639 8.62256C6.97456 8.62256 6.88696 8.6035 6.8071 8.56898C6.7278 8.5348 6.65345 8.48422 6.58882 8.4173C6.58834 8.4168 6.58786 8.4163 6.5874 8.4158M6.58706 8.41544L0.264821 1.82758C0.000267833 1.55191 0.000267833 1.10498 0.264821 0.82931C0.529375 0.553641 0.95823 0.553641 1.22278 0.82931L6.38898 6.21251L7.06637 6.91843L7.74383 6.21251L12.91 0.82931' fill='%2328939C'/%3E%3C/g%3E%3C/svg%3E%0A");

    &::-ms-expand {
        display: none;
    }
`;
