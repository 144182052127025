import { SetStateAction } from 'react';

import { INavigation } from '@/interfaces/navigation';

import MenuItem from './MenuItem';
import { Nav, NavUl } from './styles';

interface INavbar {
    items: Array<INavigation>;
    navActive: boolean;
    setNavActive: React.Dispatch<SetStateAction<boolean>>;
}
const Navigation = ({ items, navActive, setNavActive }: INavbar) => {
    const depthLevel = 0;
    return (
        <Nav className={navActive ? 'active' : ''}>
            <NavUl className="menus">
                {items.map((menu, index) => (
                    <MenuItem items={menu} key={index} depthLevel={depthLevel} setNavActive={setNavActive} />
                ))}
            </NavUl>
        </Nav>
    );
};

export default Navigation;
