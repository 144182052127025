import Link from 'next/link';

import { INavigation } from '@/interfaces/navigation';
import { Container } from '@/styles/global';

import BackToTop from '../BackToTop';
import { FooterWrap, Nav } from './styles';

interface IFooter {
    items: Array<INavigation>;
}

const Footer = ({ items }: IFooter) => (
    <FooterWrap>
        <Container>
            <Nav>
                {items.map((link, idx) => (
                    <div key={idx}>
                        {link.newWindow ? (
                            <a href={link.url} target="_blank" rel="noreferrer">
                                {link.text}
                            </a>
                        ) : (
                            <Link href={link.url}>{link.text}</Link>
                        )}
                    </div>
                ))}
            </Nav>
        </Container>
        <BackToTop />
    </FooterWrap>
);

export default Footer;
